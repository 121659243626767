import { useClearRefinements } from 'react-instantsearch';

import type { FunctionComponent } from 'react';
import type { TypeClearRefinementsProps } from './types';

/**
 * ClearRefinements
 */
export const ClearRefinements: FunctionComponent<TypeClearRefinementsProps> = ({
  className = '',
  includedAttributes,
  label = 'Borrar Filtros',
}: TypeClearRefinementsProps) => {
  const { canRefine, refine } = useClearRefinements({
    ...(includedAttributes ? { includedAttributes: includedAttributes } : {}),
  });

  return (
    <span
      onClick={(e) => {
        e.preventDefault();
        refine();
      }}
      aria-disabled={!canRefine}
      className={`u-body u-body--xs capitalize text-secondary-500 underline aria-disabled:pointer-events-none aria-disabled:text-primary-200 ${className} `}
      role="button"
    >
      {label}
    </span>
  );
};

ClearRefinements.displayName = 'ClearRefinements';
