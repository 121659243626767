import { usePagination } from 'react-instantsearch';

import { ArrowLeft, ArrowRight } from '@/icons';
import { Page as PageAtom } from '@/atoms';

import type { FunctionComponent, MouseEvent } from 'react';
import type { TypePaginationProps } from './types';

// TODO: Refactor to use Pagination molecule

/**
 * Pagination
 */
export const Pagination: FunctionComponent<TypePaginationProps> = ({
  className = '',
  parentRef,
}: TypePaginationProps) => {
  const {
    nbPages,
    refine,
    currentRefinement,
    isFirstPage,
    isLastPage,
    canRefine,
    createURL,
  } = usePagination();

  const navigate = (page: number) => {
    if (canRefine) {
      refine(page);
      window.scrollTo({
        top: (parentRef.current?.offsetTop ?? 0) - 300,
        behavior: 'smooth',
        left: 0,
      });
    }
  };
  const isActive = (page: number) => currentRefinement === page;

  const firstPageIndex = 0;
  const lastPageIndex = nbPages - 1;
  const pagesArray = Array.from({ length: nbPages }, (_, i) => i);

  pagesArray.shift();
  pagesArray.pop();

  const Page = ({ page }: { page: number }) => (
    <PageAtom
      href={createURL(page)}
      onClick={(e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        navigate(page);
      }}
      data-active={isActive(page)}
      aria-disabled={isActive(page)}
    >
      {page + 1}
    </PageAtom>
  );

  return nbPages > 1 ? (
    <div className={`inline-flex items-center ${className}`}>
      <a
        href={createURL(currentRefinement - 1)}
        aria-disabled={isFirstPage}
        onClick={(e) => {
          e.preventDefault();
          navigate(currentRefinement - 1);
        }}
        className="mr-3 aria-disabled:pointer-events-none aria-disabled:cursor-default aria-disabled:text-primary-200"
      >
        <ArrowLeft width={22} height={18} />
      </a>

      <Page page={firstPageIndex} />

      {isFirstPage &&
        pagesArray.slice(0, 2).map((page) => <Page page={page} key={page} />)}

      {currentRefinement - firstPageIndex > 2 && (
        <PageAtom as="div" className={nbPages < 5 ? 'hidden' : ''}>
          ...
        </PageAtom>
      )}

      {!isFirstPage &&
        !isLastPage &&
        pagesArray
          .slice(
            currentRefinement - 2 > 0 ? currentRefinement - 2 : 0,
            currentRefinement + 1,
          )
          .map((page) => <Page page={page} key={page} />)}

      {nbPages - currentRefinement - 1 > 2 && (
        <PageAtom as="div" className={nbPages < 5 ? 'hidden' : ''}>
          ...
        </PageAtom>
      )}

      {isLastPage &&
        pagesArray.slice(-2).map((page) => <Page page={page} key={page} />)}

      <Page page={lastPageIndex} />

      <a
        {...(!isLastPage && {
          href: createURL(currentRefinement + 1),
          onClick: (e) => {
            e.preventDefault();
            navigate(currentRefinement + 1);
          },
        })}
        aria-disabled={isLastPage}
        className="ml-3 aria-disabled:pointer-events-none aria-disabled:cursor-default aria-disabled:text-primary-200"
      >
        <ArrowRight width={22} height={18} />
      </a>
    </div>
  ) : null;
};

Pagination.displayName = 'Pagination';
