import { useHits } from 'react-instantsearch';

import { Button } from '@/molecules';

import type { FunctionComponent } from 'react';
import type { TypeButtonHitsProps } from './types';

/**
 * ButtonHits
 */
export const ButtonHits: FunctionComponent<TypeButtonHitsProps> = ({
  children,
  ...props
}: TypeButtonHitsProps) => {
  const { results } = useHits();

  return <Button {...props}>{children(results?.nbHits ?? 0)}</Button>;
};

ButtonHits.displayName = 'ButtonHits';
