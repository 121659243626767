import { Disclosure } from '@headlessui/react';
import { oneLine } from 'common-tags';

import AccordionBody from '@/molecules/accordion/body';
import AccordionHeader from '@/molecules/accordion/header';

import type { FunctionComponent } from 'react';
import type { AccordionProps, TypeAccordion } from './types';

/**
 * Primary UI component for user interaction
 */
export const Accordion: FunctionComponent<AccordionProps> = ({
  items,
  level = 'primary',
  className = '',
  accordionClassName = '',
  accordionHeaderClass = '',
  accordionBodyClass = '',
  accordionActiveHeaderClass = '',
  accordionActiveBodyClass = '',
  wrapperClass = '',
  titleClass = '',
  defaultOpen = false,
}: AccordionProps) => (
  <dl className={`relative w-full ${className}`}>
    {items.map((item: TypeAccordion, index) => (
      <Disclosure
        defaultOpen={defaultOpen || item.defaultOpen}
        as="div"
        key={index}
        className={`${level === 'primary' ? 'mb-2' : ''} ${level === 'secondary' ? 'mb-0' : ''} ${accordionClassName}`}
      >
        {({ open }) => (
          <>
            <dt>
              <Disclosure.Button className="w-full">
                <AccordionHeader
                  index={index + 1}
                  opened={open}
                  title={item.question}
                  level={level}
                  titleClass={titleClass}
                  icon={item.icon ?? null}
                  className={oneLine`${
                    index === 0 && level === 'secondary'
                      ? '!border-t border-primary-100'
                      : ''
                  } ${accordionHeaderClass} ${open ? accordionActiveHeaderClass : ''} `}
                />
              </Disclosure.Button>
            </dt>
            <Disclosure.Panel unmount={false} as="dd">
              <AccordionBody
                body={item.answer}
                level={level}
                wrapperClass={wrapperClass}
                accordionBodyClass={oneLine`
                  min-w-full
                  ${accordionBodyClass}
                  ${open ? accordionActiveBodyClass : ''}
                  ${item.className ?? ''}
                `}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    ))}
  </dl>
);
