import { forwardRef } from 'react';

import '@/lib/utils/forwardRef';

import type { PolymorphicRef } from '@/types';
import type { ElementType } from 'react';
import type { PageProps } from './types';

/**
 * Page
 */
const PageBase = <T extends ElementType = 'a'>(
  { as, className = '', children, ...props }: PageProps<T>,
  ref: PolymorphicRef<T>,
) => {
  const HTMLTag = as || 'a';

  return (
    <HTMLTag
      className={`u-actions u-actions--nav flex h-8 w-8 items-center justify-center p-1 aria-disabled:pointer-events-none aria-disabled:cursor-default data-[active=true]:bg-primary-900 data-[active=true]:text-typo-alternative ${className}`}
      {...props}
      ref={ref}
    >
      {children}
    </HTMLTag>
  );
};

PageBase.displayName = 'Page';

export const Page = forwardRef(PageBase);
