import { useHits } from 'react-instantsearch';

import type { FunctionComponent } from 'react';
import type { TypeTitleProps } from './types';

/**
 * Title
 */
export const Title: FunctionComponent<TypeTitleProps> = ({
  title,
}: TypeTitleProps) => {
  const { results } = useHits();

  return (
    <>
      {title} ({results?.nbHits})
    </>
  );
};

Title.displayName = 'Title';
