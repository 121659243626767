import { useState } from 'react';
import { useSearchBox } from 'react-instantsearch';

import type { FunctionComponent } from 'react';
import type { TypeSearchBoxProps } from './types';

/**
 * SearchBox
 */
export const SearchBox: FunctionComponent<TypeSearchBoxProps> = ({
  className = '',
  label,
  id,
}: TypeSearchBoxProps) => {
  const { query, refine } = useSearchBox();
  const [focused, setFocused] = useState(Boolean(query));

  return (
    <div className={`${className} relative`} {...(id && { id })}>
      <label
        className={`pointer-events-none absolute left-3 z-10 text-primary-300 ${focused || query ? 'u-label u-label--xs top-1' : 'u-body top-3'} `}
      >
        {label}
      </label>

      <input
        value={query}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
        className="relative flex h-12 w-full appearance-none rounded-none border border-primary-300 px-3 pt-4 !text-primary-400 outline-none focus:border-typo-primary focus:!text-primary-600 focus:ring-0 sm:text-sm"
        onChange={(e) => {
          refine(e.target.value);
        }}
      />
    </div>
  );
};

SearchBox.displayName = 'SearchBox';
