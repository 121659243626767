import { useEffect, useRef } from 'react';
import { useHits } from 'react-instantsearch';

import { Grid } from '@/molecules';

import { CardProductAlgolia } from '@/components/algolia';
import { useIntersectionObserver } from '@/lib/hooks';
import { gtmPush } from '@/lib/utils';
import { normalizeAlgoliaItems } from '../../../lib/utils/gtm/normalizeAlgoliaItems';

import type { FunctionComponent } from 'react';
import type { TypeGridCardHitsProps } from './types';

/**
 * GridCardHits
 */
export const GridCardHits: FunctionComponent<TypeGridCardHitsProps> = ({
  className,
  itemListId,
  itemListName,
  lg = 4,
  xl = 4,
}: TypeGridCardHitsProps) => {
  const { hits, sendEvent } = useHits();

  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {
    freezeOnceVisible: true,
  });
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    if (isVisible && hits.length) {
      gtmPush({
        event: 'view_item_list',
        ecommerce: {
          items: normalizeAlgoliaItems(hits, itemListId, itemListName),
        },
      });
    }
  }, [isVisible, hits, itemListId, itemListName]);

  return (
    <Grid
      md={4}
      lg={lg}
      xl={xl}
      {...(className && { className })}
      refProp={ref}
    >
      {hits.map((hit: any, key) => (
        <Grid.Item
          colSpanXXS={hit?.card?.double_wide ? 2 : 1}
          colSpanSM={1}
          key={hit?.id ?? key}
        >
          <CardProductAlgolia
            hit={hit}
            sendEvent={sendEvent}
            itemListId={itemListId}
            itemListName={itemListName}
            itemIndex={key}
            numColumns={xl}
          />
        </Grid.Item>
      ))}
    </Grid>
  );
};

GridCardHits.displayName = 'GridCardHits';
