import { oneLine } from 'common-tags';

import { ChevronDown } from '@/icons';

import type { FunctionComponent } from 'react';
import type { AccordionHeaderProps } from './types';

/**
 * Primary UI component for user interaction
 */
export const AccordionHeader: FunctionComponent<AccordionHeaderProps> = ({
  className = '',
  titleClass = '',
  index,
  title,
  opened,
  level = 'primary',
  icon = null,
}: AccordionHeaderProps) => (
  <div
    // prettier-ignore
    className={oneLine`
      ${level === 'primary' ? 'relative' : ''}
      ${level === 'secondary' ? 'border border-t-0 border-primary-100' : ''}
      ${level === 'tertiary' ? 'border-none' : ''}
      ${className}
    `}
  >
    <div
      // prettier-ignore
      className={oneLine`
        flex w-full items-center justify-between px-4 py-2 text-left
        ${level === 'primary' ? 'bg-primary-900' : ''}
        ${level === 'secondary' ? 'bg-white' : ''}
        ${level === 'tertiary' ? 'px-0' : ''}
        ${className}
      `}
    >
      <span
        // prettier-ignore
        className={oneLine`
          flex w-full flex-row items-center [&_svg]:mr-2
          ${level === 'primary' ? 'u-actions u-actions--nav text-white' : ''}
          ${
            level === 'secondary'
              ? 'u-body u-body--s font-normal normal-case text-primary-900'
              : ''
          }  
          ${
            level === 'tertiary'
              ? 'u-headline u-headline@mobile--h3 font-normal text-primary-900'
              : ''
          }
          ${titleClass}
        `}
      >
        {icon ? icon : ''}
        {level === 'primary' && !icon ? `${index}. ${title}` : title}
      </span>
      <ChevronDown
        color={
          level === 'primary'
            ? 'white'
            : level === 'secondary' || level === 'tertiary'
              ? 'var(--tw-color-secondary-500)'
              : 'currentColor'
        }
        className={`transition ${opened ? '-rotate-180' : 'rotate-0'}`}
      />
    </div>
  </div>
);
