import type { FunctionComponent } from 'react';
import type { AccordionBodyProps } from './types';

/**
 * Primary UI component for user interaction
 */
export const AccordionBody: FunctionComponent<AccordionBodyProps> = ({
  body,
  accordionBodyClass = '',
  wrapperClass = '',
  level = 'primary',
}: AccordionBodyProps) => (
  <div
    className={`bg-white ${
      level !== 'tertiary'
        ? '[&>*:not(a):not(button)]:u-body [&>*:not(a):not(button)]:u-body--xs [&>*:not(a):not(button)]:text-primary-600'
        : ''
    } ${accordionBodyClass}`}
  >
    <div
      className={`${
        level === 'tertiary' ? '!border-none !px-0' : ''
      } border border-t-0 border-primary-100 px-4 py-2 ${wrapperClass} ${accordionBodyClass}`}
    >
      {body}
    </div>
  </div>
);
